.wrapper {
  display: flex;
}

.description {
  resize: none;
  width: 100%;
  height: 500px;
}

.titleInput {
  width: 100%;
}